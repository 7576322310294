// Applies/removes CSS classes to show or hide the search bar
// on mobile
const scrollDownClass = "scroll-down";
const scrollUpClass = "scroll-up";
const timeoutDuration = 50;
let lastScrollPosition = 0;
let animationTimeout: number;

const navElement = <HTMLElement>document.querySelector(".js-mainHeader");
const pageBody = <HTMLElement>document.querySelector(".p-pageWrapper");
const pageBodyHeight = pageBody.offsetHeight ? pageBody.offsetHeight : 0;
const viewportHeight = window.innerHeight ? window.innerHeight : 0;
const navElementHeight = navElement.offsetHeight ? navElement.offsetHeight : 0;
if (!navElement) throw new Error("Could not find header");

function handleAnimationClasses(scrollDiff: number, el: Element) {
  // If the page body isn't large enough to be able to scroll down past where the
  // nav element should be hidden, just keep the nave element fixed
  if (pageBodyHeight < viewportHeight + navElementHeight) {
    return;
  }

  if (scrollDiff > 0) {
    // scrolling down
    el.classList.remove(scrollUpClass);
    el.classList.add(scrollDownClass);
  } else if (scrollDiff < 0) {
    // scrolling up
    el.classList.remove(scrollDownClass);
    el.classList.add(scrollUpClass);
  }
  animationTimeout = 0;
}

// Set a timeout for showing/hiding so that tiny accidental scroll events
// don't make the animations go on the fritz
function setAnimationTimeout(scrollDiff: number, el: Element) {
  clearTimeout(animationTimeout);
  animationTimeout = window.setTimeout(
    handleAnimationClasses,
    timeoutDuration,
    scrollDiff,
    el
  );
}

window.addEventListener("scroll", () => {
  const currentScroll = window.pageYOffset;

  if (currentScroll <= 0) {
    navElement.classList.remove(scrollUpClass);
    return;
  }

  const scrollDiff = currentScroll - lastScrollPosition;

  // Perform the animation immediately and then set a timeout for subsequent
  // scrolls so the animation doesn't flicker back and forth if small scrolls
  // happen in both directions
  if (!animationTimeout) {
    handleAnimationClasses(scrollDiff, navElement);
  }

  setAnimationTimeout(scrollDiff, navElement);

  lastScrollPosition = currentScroll;
});
